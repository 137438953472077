<template>
  <div class="add-tag-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="添加预约"
      :isShowFooter="true">

    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { tips } from '@/utils/tips'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }





    const initPage = () => {

    }
    initPage()





    return {
      changeDialogVisible,
      COMPANY_ID,
    }

  }
})
</script>

<style scoped lang="less">
.add-tag-dialog {
  .update-name-form {
    display: flex;

    .sure-btn {
      margin-left: 10px;
    }
  }

  &:deep(.el-checkbox-button--small) {
    margin-top: 10px;

  }

  .remove {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tips {
      color: #ccc;
      font-size: 12px;
    }
  }

  .tag-list {
    &:deep(.el-checkbox-button) {
      border: 1px solid #dcdfe6;
      margin-right: 10px;

      span {
        border: none !important;
      }
    }
  }

  .top-form {
    display: flex;

    .add-btn {
      margin-left: 10px;
    }
  }

  &:deep(.el-select) {
    max-width: 160px;
  }
}
</style>
