<template>
  <div class="add-client-dialog">
    <addClientDialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="新增用户">
      <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" :rules="addClientRules" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="姓名:" prop="userRealname">
          <el-input v-model="ruleForm.userRealname" />
        </el-form-item>
        <el-form-item label="电话:" prop="userMobile">
          <el-input v-model="ruleForm.userMobile" />
        </el-form-item>
        <el-form-item label="性别:" prop="gender">
          <el-radio-group v-model="ruleForm.gender">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择门店:" prop="shopId">
          <el-select v-model="ruleForm.shopId" class="m-2" placeholder="选择门店">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId" />
          </el-select>
        </el-form-item>
      </el-form>

      <div class="client-btn">
        <el-button @click="handleSaveClick(ruleFormRef)" size="small" type="primary">保存</el-button>
      </div>
    </addClientDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import addClientDialog from '@/components/page-dialog/page-dialog.vue'
import { addClientRules } from '../../../config'
import { getShopList, addClient } from '@/service/main/customer'
import { ElMessage } from 'element-plus'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    addClientDialog
  },
  setup(props, { emit }) {

    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const ruleFormRef = ref()
    const ruleForm = reactive({
      userRealname: "",
      userMobile: "",
      shopId: "",
      gender: 0,
      companyId: COMPANY_ID
    })

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await addClient({ ...ruleForm })
          if (res.code == 0) {
            if (res.data != '手机号码已注册') {
              ElMessage({
                message: '操作成功!',
                type: 'success',
              })
              emitter.emit('refreshCurrentConsumeList')
              emit('changeDialogVisible', false)
            } else {
              ElMessage({
                showClose: true,
                message: '手机号码已注册',
                type: 'warning',
              })
            }

          }
          // console.log('提交');
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const shopList = ref([])

    const initPage = async () => {
      const res = await getShopList(10000)
      shopList.value = res.data.list

    }
    initPage()


    return {
      shopList,
      ruleForm,
      ruleFormRef,
      changeDialogVisible,
      addClientRules,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.add-client-dialog {
  .client-btn {
    text-align: right;
  }
}
</style>




