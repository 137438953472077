export const contentTableConfig = {
  propList: [
    {
      prop: 'userName',
      label: '预约人',
      minWidth: '120',
    },
    {
      prop: 'phone',
      label: '客户手机号',
      minWidth: '120',
    },
    { prop: 'serveName', label: '预约服务', minWidth: '220', slotName: 'serveName' },
    {
      prop: 'empName',
      label: '理疗师',
      minWidth: '80',
      slotName: 'empName'
    },

    {
      prop: 'shopName',
      label: '预约门店',
      minWidth: '140',
      slotName: 'shopName'
    },
    {
      prop: 'createTime',
      label: '下单时间',
      minWidth: '150',
      slotName: 'createTime'
    },
    {
      prop: 'code',
      label: '预约单号',
      minWidth: '180',
      slotName: 'appNo'
    },
    {
      prop: 'status',
      label: '状态',
      minWidth: '100',
      slotName: 'status'
    },
    {
      prop: 'appTime',
      label: '预约时间',
      minWidth: '150'
    },
    {
      prop: 'payTime',
      label: '支付时间',
      minWidth: '150'
    },
    {
      prop: 'cleanTime',
      label: '取消时间',
      minWidth: '150'
    },

    { label: '操作', minWidth: '230', slotName: 'handler',fixed:'right' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}
