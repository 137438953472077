<template>
  <div class="reserve-list">
    <el-tabs type="card" class="tabs" @tab-click="handleClick" v-model="queryInfo.status">
      <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.title" :name="item.name">
        <all @refreshPage="refreshPage"></all>
        <el-pagination v-model:currentPage="queryInfo.current" v-model:page-size="queryInfo.size"  :page-sizes="[10, 20, 30, 40]" :background="background" layout="total, sizes, prev, pager, next, jumper" :total="reserveListCount" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </el-tab-pane>
    </el-tabs>
    <div class="pagination ">
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import All from './cpns/all/all.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'

export default defineComponent({
  props: {

  },
  components: {
    All
  },
  setup() {
    const store = useStore()
    const activeTabs = ref('all')
    let queryInfo = reactive({
      // // keyword: '',
      // // source: '',
      // empId: '',
      // shopId: '',
      current: 1,
      // startDate: '',
      // endDate: '',
      size: 10,
      status: 0,
      // type: 0
    })
    const tabList = ref([{name:0,title:'全部'},{name:1,title:'待到店'},{name:2,title:'已到店'},{name:3,title:'已开单'},{name:4,title:'已完成'},{name:5,title:'已取消'},{name:6,title:'已退款'}])

    emitter.on('changeReserveTopContentShopid', value => {
      // console.log(value);
      queryInfo.shopId = value
    })

    emitter.on('changeReserveTopContentSource', value => {
      queryInfo.source = value
    })

    emitter.on('changeReserveTopContentTeacher', value => {
      queryInfo.empId = value
    })

    emitter.on('changeReserveTopContentKeyword', value => {
      queryInfo.keyword = value
    })

    emitter.on('changeReserveTopContentDate', value => {
      queryInfo.startDate = value.startTime
      queryInfo.endDate = value.endTime
    })

    emitter.on('changeReserveTopContentSearch', () => {
     let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined
      }
      store.dispatch('reserve/getReserveListAction', params)
    })

    emitter.on('refreshCyrrentReserveListPage', () => {
     let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined
      }
      store.dispatch('reserve/getReserveListAction', params)
    })

    watch(() => queryInfo.current, () => {
     let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined
      }
      store.dispatch('reserve/getReserveListAction', params)
    })
    watch(() => queryInfo.size, () => {
     let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined
      }
      store.dispatch('reserve/getReserveListAction', params)
    })


    const handleCurrentChange = page => {
      queryInfo.current = page
    }

    const handleSizeChange = (size) => {
      queryInfo.size = size
    }
    const handleClick = (e) => {
      let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined,
        current:1
      }
      store.dispatch('reserve/getReserveListAction', params)
    }



    const reserveListCount = computed(() => store.state.reserve.reserveListCount)


    const initPage = () => {
      let adminInfo = cache.getCache('adminInfo')
      let shopId = adminInfo.shopId?adminInfo.shopId:''
      queryInfo.shopId = shopId

     let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined
      }
      store.dispatch('reserve/getReserveListAction', params)

    }
    initPage()
    const refreshPage = () =>{
      let params = {
        ...queryInfo,
        status:queryInfo.status&&queryInfo.status!=0?queryInfo.status:undefined
      }
      store.dispatch('reserve/getReserveListAction', params)
    }


    onUnmounted(() => {
      emitter.off('refreshCyrrentReserveListPage')
      emitter.off('changeReserveTopContentSearch')
      emitter.off('changeReserveTopContentDate')
      emitter.off('changeReserveTopContentKeyword')
      emitter.off('changeReserveTopContentTeacher')
      emitter.off('changeReserveTopContentSource')
      emitter.off('changeReserveTopContentShopid')
    })


    return {
      handleSizeChange,
      reserveListCount,
      activeTabs,
      COMPANY_ID,
      queryInfo,
      handleCurrentChange,
      handleClick,
      tabList,
      refreshPage
    }

  }
})
</script>

<style scoped lang="less">
.reserve-list {
  margin-top: 20px;

  .pagination {
    text-align: right;
  }

  .tabs {
  }
}
</style>




