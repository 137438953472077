<template>
  <div class="list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="list-content">
      <top-content></top-content>
      <reserve-list></reserve-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import TopContent from './cpns/top-content/top-content.vue'
import ReserveList from './cpns/reserve-list/reserve-list.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    TopContent,
    ReserveList
  },
  setup() {




    return {
      breadcrumbList,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
.list-content {
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  min-width: 960px;
}
</style>




