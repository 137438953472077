<template>
  <div class="all">
    <page-content :contentTableConfig="contentTableConfig" :dataList='allReserveList' :isButtonStyle="true">
      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini" @click="handleEditClick(scope.row)">详情</el-button>
          <el-button plain size="mini" @click.stop="handleCardClick(scope.row)">开卡</el-button>
          <el-button v-if="scope.row.status == 2 || scope.row.payStatus == 1" plain size="mini" @click.stop="handleBillingClick(scope.row)">开单
          </el-button>
        </div>
      </template>

      <template #status="scope">
        <template v-if="scope.row.status == 1">
          <span class="el-tag el-tag--mini el-tag--plain">待到店</span>
        </template>
        <template v-if="scope.row.status == 2">
          <span class="el-tag el-tag--success el-tag--mini el-tag--plain">已到店</span>
        </template>
        <template v-if="scope.row.status == 3">
          <span class="el-tag el-tag--info el-tag--mini el-tag--plain">已开单</span>
        </template>
        <template v-if="scope.row.status == 4">
          <span class="el-tag el-tag--danger el-tag--mini el-tag--plain"> 已完成</span>
        </template>
        <template v-if="scope.row.status == 5">
          <span class="el-tag el-tag--warning el-tag--mini el-tag--plain">已取消</span>
        </template>
        <template v-if="scope.row.status == 6">
          <span class="el-tag el-tag--danger el-tag--mini el-tag--plain">已退款</span>
        </template>
      </template>

    </page-content>
    <reserve-edit-draw v-if="pageDialogVisible" :reserve="currentReserve" :shopTeacherListData="shopTeacherListData" @changedDrawer="handleGoodsEditdDrawer" :dialogVisible="pageDialogVisible"></reserve-edit-draw>
    <template v-if="paydialogVisible">
      <payDialog
        :paydialogVisible="paydialogVisible"
        :isKaika="true"
        :curentOrderInfo="curentOrderInfo"
        @changeDialogVisible="payChangeDialogVisible"
        :dialogVisible="paydialogVisible"
        @paySuccess="paySuccess"
      >
      </payDialog>
  </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed,watch } from 'vue'
import pageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from '../../config'
import store from '@/store'
import reserveEditDraw from '../base-ui/reserve-edit-draw/reserve-edit-draw'
import {getAppointment,pullCashier,getReserveShopList} from '@/service/main/reserve'
import { Flag } from '@element-plus/icons'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import payDialog from '../../../../../../order/base-ui/pay-dialog/pay-dialog.vue'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {
    pageContent,
    reserveEditDraw,
    payDialog
  },
  emits:['refreshPage'],
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const allReserveList = computed(() => store.state.reserve.allReserveList)
    const currentReserve = ref('')

    const pageDialogVisible = ref(false)
    const handleGoodsEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const shopTeacherListData = ref([])
    const handleEditClick = async(item) => {
      const data = await getAppointment(item.id)
      const teaRes = await getReserveShopList(data.data.shopId)
      let teacherList = teaRes.data.list
      teacherList = teacherList.filter(ele => ele.status == 1)
      shopTeacherListData.value = teacherList
      currentReserve.value = data.data
      pageDialogVisible.value = true
    }

    const handleCardClick = (item) => {
      router.replace({
        path: '/main/order/openCard',
        query: {
          userId: item.userId,
          phone: item.phone,
          empId:item.empId,
          empName:item.empName,
          shopId:item.shopId
        }
      })

    }
    const curentOrderInfo = ref({})
    const paydialogVisible = ref(false)
    const handleBillingClick = async (item) => {
      if (item.isBill) {
        let payData = await pullCashier(item.orderId)
        curentOrderInfo.value = payData.data
        paydialogVisible.value=true
        setTimeout(() => {
          emitter.emit('changeOrderPayDialogSearchPayState')
        }, 2000);
        return
      }
      router.push({
        path: '/main/order/billing',
        query: {
          userId: item.empId,
          id:item.id,
          phone: item.phone,
          shopId:item.shopId
        }
      })
    }
    const payChangeDialogVisible = (flag) => {
      paydialogVisible.value = flag
      emit('refreshPage')
    }
    const paySuccess = () => {
      paydialogVisible.value = false
      emit('refreshPage')
    }

    watch(paydialogVisible, (n) => {
      if (n == false) {
        setTimeout(() => {
          clearTimeout(window.timer)
        }, 5000);
      }
      // console.log(n);

    }, {
      deep: true
    })

    const formatDate = (date, formatStr) => {
      return dayjs(date).format(formatStr)
    }

    return {
      formatDate,
      handleBillingClick,
      handleCardClick,
      handleEditClick,
      handleGoodsEditdDrawer,
      pageDialogVisible,
      contentTableConfig,
      allReserveList,
      currentReserve,
      dayjs,
      paydialogVisible,
      payChangeDialogVisible,
      curentOrderInfo,
      paySuccess,
      shopTeacherListData
    }

  }
})
</script>

<style scoped lang="less">
.all {
  font-size: 12px;

  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }
}
</style>




