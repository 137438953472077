export const genderList = [
  {
    value: 1,
    label: '男'
  },
  {
    value: 2,
    label: '女'
  }
]

export const isHasCard = [
  {
    value: 1,
    label: '会员(持卡)'
  },
  {
    value: 0,
    label: '客户(无卡)'
  }
]

export const addClientRules = {
  userRealname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  userMobile: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { pattern:/^1[3456789]\d{9}$/, message: "请输入合法手机号/电话号", trigger: "blur" }
  ],
  gender: [{ required: true, message: '请选择性别', trigger: 'blur' }],
  shopId: [{ required: true, message: '请选择门店', trigger: 'blur' }]
}
