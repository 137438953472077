<template>
    <div class="top-content">
        <el-button class="add-staff"
            type="primary"
            @click="handleAddStaffClick">
            <el-icon>
                <plus />
            </el-icon>新增会员
        </el-button>

        <div class="date">
            <el-date-picker @change="handleTimeChange"
                v-model="ruleForm.date"
                type="daterange"
                style="width: 250px;"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期" />
        </div>

        <div class="source">
            <el-select @change="handleSourceChange"
                v-model="ruleForm.source"
                class="channel"
                style="width: 150px;"
                placeholder="选择渠道"
                size="large">
                <el-option v-for="item in reservePaySourceList"
                    :key="item.id"
                    :label="item.sourceName"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div class="shop">
            <el-select @change="handleShopChange"
                v-model="ruleForm.shopId"
                class="m-2"
                placeholder="选择门店"
                size="large">
                <el-option v-for="item in shopList"
                    :key="item.shopId"
                    :label="item.shopName"
                    :value="item.shopId">
                </el-option>
            </el-select>
        </div>

        <div class="teacher">
            <el-select @change="handleTeacherChange"
                v-model="ruleForm.empId"
                style="width: 150px;"
                placeholder="选择理疗师"
                size="large">
                <el-option v-for="item in shopTeacherList"
                    :key="item.userId"
                    :label="item.userRealname"
                    :value="item.userId" />
            </el-select>
        </div>

        <div class="search">
            <el-input clearable
                @change="handleSearchChange"
                prefix-icon="search"
                v-model="ruleForm.keyword"
                placeholder="到店人/预约人信息关键字" />
        </div>
        <div class="search-btn">
            <el-button @click="handleSerchClikc"
                class="search-btn"
                type="primary">搜索</el-button>
        </div>

        <add-reserve-dialog :dialogVisible="addReserveDialogVisible"
            @changeDialogVisible="changeAddReserveDialog">
        </add-reserve-dialog>
        <addClientDialog v-if="addClientDialogVisible"
            :dialogVisible="addClientDialogVisible"
            @changeDialogVisible="changeClientDialogVisible">
        </addClientDialog>
    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import addReserveDialog from './cpns/add-reserve-dialog/add-reserve-dialog'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import cache from '@/utils/cache'
import dayjs from 'dayjs'
import addClientDialog from '@/views/main/customer/list/cpns/top-content/cpns/add-client-dialog/add-client-dialog'
export default defineComponent({
  props: {

  },
  components: {
    addReserveDialog,
    addClientDialog
  },
  setup() {
    const store = useStore()
    const handleAddReserveClick = () => {
      addReserveDialogVisible.value = true
    }

    const ruleForm = reactive({
      shopId: "",
      source: "",
      empId: "",
      date: ""
    })

    const addReserveDialogVisible = ref(false)
    const changeAddReserveDialog = (flag) => {
      addReserveDialogVisible.value = flag
    }
    const handleShopChange = (value) => {
      ruleForm.empId = ''
      store.dispatch('reserve/getShopTeacherListAction', value)
      emitter.emit('changeReserveTopContentShopid', value)
    }
    const handleSourceChange = (value) => {
      emitter.emit('changeReserveTopContentSource', value)
    }

    const handleTeacherChange = (value) => {
      emitter.emit('changeReserveTopContentTeacher', value)
    }

    const handleSearchChange = (value) => {
      emitter.emit('changeReserveTopContentKeyword', value)
    }

    const handleTimeChange = (value) => {
      if(value){
        let obj = {
          startTime: '',
          endTime: ''
        }
        obj.startTime = dayjs(value[0]).format('YYYY-MM-DD')
        obj.endTime = dayjs(value[1]).format('YYYY-MM-DD')
        emitter.emit('changeReserveTopContentDate', obj)
      }else{
        let obj = {
          startTime: '',
          endTime: ''
        }
        emitter.emit('changeReserveTopContentDate', obj)
      }
      
    }

    const handleSerchClikc = () => {
      emitter.emit('changeReserveTopContentSearch')
    }


    const shopList = computed(() => store.state.reserve.shopList)
    const reservePaySourceList = computed(() => store.state.reserve.reservePaySourceList)
    const shopTeacherList = computed(() => store.state.reserve.shopTeacherList)

    const initPage = () => {
      let adminInfo = cache.getCache('adminInfo')
      ruleForm.shopId = adminInfo.shopId?adminInfo.shopId:''
      store.dispatch('reserve/getShopTeacherListAction', adminInfo.shopId)
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      store.dispatch('reserve/getReservePaySourceListAction')
    }
    initPage()


    const addClientDialogVisible = ref(false)
    const handleAddStaffClick = () => {
      addClientDialogVisible.value = true
    }

    const changeClientDialogVisible = (flag) => {
      addClientDialogVisible.value = flag
    }


    return {
      changeClientDialogVisible,
      handleAddStaffClick,
      addClientDialogVisible,
      handleSerchClikc,
      handleTimeChange,
      handleSearchChange,
      handleTeacherChange,
      shopTeacherList,
      handleSourceChange,
      reservePaySourceList,
      shopList,
      changeAddReserveDialog,
      addReserveDialogVisible,
      handleAddReserveClick,
      COMPANY_ID,
      ruleForm,
      handleShopChange
    }

  }
})
</script>

<style scoped lang="less">
// @media screen and (max-width: 1360px) {
//   .top-content {
//     &:deep(.el-date-editor) {
//       margin-bottom: 0px;
//     }

//     .search-btn {
//       &:deep(.el-button) {
//         margin-right: 10px;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1525px) {
//   .top-content {
//     &:deep(.el-date-editor) {
//       margin-bottom: 10px;
//     }

//     .search-btn {
//       &:deep(.el-button) {
//         margin-right: 10px;
//       }
//     }
//   }
// }

@media screen and (max-width: 1560px) {
  .top-content {
    flex-wrap: nowrap;

    & > div {
      margin-bottom: 10px;
    }
  }

  &:deep(.el-button) {
    margin-bottom: 10px;
  }
}

.top-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .add-staff {
    margin-right: 10px;
  }

  .teacher,
  .date,
  .source,
  .shop,
  .search {
    margin-right: 10px;
  }

  .search {
    &:deep(.el-input) {
      width: 180px;
    }
  }
}
</style>




