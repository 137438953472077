export const breadcrumbList = [
  {
    name: '预约管理',
    path: ''
  },
  {
    name: '预约列表',
    path: ''
  }
]
